// Custom Scrollbar
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 20px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primaryLight;
}

// Tab panel
.css-19kzrtu {
    padding: 20px 0px 0px 0px;
}

.patientStats__container {
    background-color: #f6f6f6;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;

    .css-o4b71y-MuiAccordionSummary-content {
        margin: 0;
    }
}

.complete {
    color: green;
}

.incomplete {
    color: red;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 10px;
}
