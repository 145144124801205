.infinitescroll__container {
    &__card {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: #f6f6f6;
        cursor: pointer;
    }

    &__info {
        display: flex;
        flex-direction: row;
    }

    &__img {
        width: 125px;
        object-fit: contain;
        margin-right: 50px;
        border-radius: 5px;
    }
    &__img__patient {
        width: 50px;
        object-fit: contain;
        margin-right: 50px;
        border-radius: 5px;
    }

    &__title {
        color: $primaryMain;
        margin-bottom: 0px;
        font-size: 22px;
    }

    &__text {
        display: flex;
        flex-direction: column;

        &__style {
            font-size: 13px;
        }

        &__content {
            display: table;
            width: 100%;
            table-layout: fixed;
            text-align: center;

            &__title {
                display: table-cell;
                font-weight: 500;
                overflow-wrap: break-word;
            }

            &__text {
                font-weight: 1000;
            }
        }
    }

    &__stats {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__text {
            color: $primaryMain;
            font-weight: 600;
            display: block;
        }

        &__icon {
            text-align: center;
            background-color: white;
            padding: 30px 30px 30px 30px;
            border-radius: 5px;
            margin-left: 10px;

            &__svg {
                fill: $primaryMain;
                width: 45px;
                height: 45px;
            }
        }
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
